// FONT VARIABLES
$font: "Cera Pro", sans-serif;

@font-face {
  font-family: "Cera Pro";
  src: url("./CeraPro/CeraPro-Light.woff2") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./CeraPro/CeraProRegular.woff2") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./CeraPro/CeraProMedium.woff2") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./CeraPro/CeraPro-Bold.woff2") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./CeraPro/CeraPro-Black.woff2") format("woff");
  font-weight: 700;
}

// Colors:
$dark: #333232;
$light: #9e9ea7;
$grey-1: #6e6d7a;
$divider: #edf0f2;
$blank: #fff;
$primary: #0c334d;
$secondary: #07508b;

body {
  font-family: $font;
  padding: 0;
  margin: 0;
}

#root {
  margin: 0;
  padding: 0;
}

.subHead {
  width: 520px;
  text-align: center;
  @media screen and (max-width: 525px) {
    width: 100%;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
}

.card {
  margin-top: 40px;
  width: 600px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // box-shadow: 0 0 36px 9px rgba(#000, 0.1);
  @media screen and (max-width: 550px) {
    width: 100%;
  }
  &_landing {
    width: 800px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  &_row {
    flex-direction: row !important;
    align-items: center;
    h1 {
      padding: 15px;
      margin: 0;
    }
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
  }
  h1 {
    font-weight: 600;
    color: $primary;
    margin-bottom: 0;
    font-size: 40px;
    @media screen and (max-width: 600px) {
      font-size: 30px;
    }
  }
  h2 {
    color: $dark;
    margin-top: 0;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  p {
    margin-top: 0;
    font-size: 16px;
    color: $grey-1;
  }
  a {
    color: $secondary;
    text-decoration: none;
    border-bottom: 1px solid $secondary;
    &:active,
    &:focus {
      text-decoration: none;
      color: $secondary;
    }
  }
}

.hint {
  .emoji {
    margin-right: 10px;
    margin-bottom: 0;
  }

  &_text {
    text-transform: uppercase;
    letter-spacing: 0.139em;
    color: $dark !important;
    font-weight: 500;
    margin-bottom: 0;
  }

  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
}

.tip {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  &_support {
    justify-content: center;
  }

  &_desc {
    margin-top: 0;
    color: $light !important;
  }
}

.gif {
  margin-top: 30px;
  margin-left: 50px;
  margin-bottom: 30px;
}

.support {
  text-transform: uppercase;
  letter-spacing: 0.139em;
  color: $dark !important;
  font-weight: 500;
  margin-bottom: 0;
  align-self: center;
  margin-top: 54px !important;
  &_info {
    align-self: center;
    margin-top: 10px !important;
  }
}

.logos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  &_img {
    height: 20px;
    width: auto;
    cursor: pointer;
    margin-right: 24px;
  }
}

.help {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin-bottom: 30px;
  &_text {
    color: $light !important;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none !important;
    border-bottom: none !important;
    &:active,
    &:focus {
      border-bottom: none !important;
      text-decoration: none !important;
      color: $light !important;
    }
  }
}

a {
  color: $secondary;
  &:active,
  &:focus {
    color: $secondary;
  }
}

ol {
  padding: 0;
}

.creds {
  margin-top: 50px;
  margin-bottom: 0;
}

.calendars {
  align-self: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  width: 50%;
  &_video {
    width: 100%;
  }
  &_img {
    width: 100px;
    height: auto;
    &-2 {
      width: 70px;
      height: auto;
    }
  }
}

.step {
  font-family: $font;
  color: $dark;
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
}

.landIMG {
  width: 500px;
  height: auto;
  align-self: center;
  @media screen and (max-width: 800px) {
    width: 80%;
  }
}

.cta {
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  img {
    transform: translateX(-22px);
    @media screen and (max-width: 600px) {
      width: 150px;
      transform: none;
    }
  }
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
  }
  h1 {
    margin-top: 0;
  }
  &_links {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 500px) {
      width: 100%;
      margin-top: 10px;
    }

    a {
      margin-left: 20px;
      margin-bottom: 0;
    }
  }
}
